<template>
  <b-row>
    <b-col cols="12">
      <b-col
        v-for="(service_plan, index) in services"
        :key="'service_plan_' + index"
        cols="4"
      >
        <b-card>
          <b-list-group flush>
            <b-list-group-item class="d-flex">
              <span class="mr-1">
                <feather-icon icon="CheckIcon" size="16" class="text-success" />
              </span>
              <span>Service Name :- {{ service_plan.service_name }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex">
              <span class="mr-1">
                <feather-icon icon="CheckIcon" size="16" class="text-success" />
              </span>
              <span> Plan Name:- {{ service_plan.plan_name }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex">
              <span class="mr-1">
                <feather-icon icon="CheckIcon" size="16" class="text-success" />
              </span>
              <span>
                Availed on :-
                {{
                  new Date(
                    service_plan.transaction_datetime
                  ).toLocaleDateString()
                }}</span
              >
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-col>
  </b-row>
</template>
<script>
import BCardCode from "@core/components/b-card-code";
import {
  BCarousel,
  BCarouselSlide,
  BButtonGroup,
  BButton,
  BLink,
  BCardText,
  BTabs,
  BTab,
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BListGroup,
  BListGroupItem,
  BCol,
  BRow,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import CommonServices from "@/apiServices/CommonServices";

export default {
  components: {
    BCardCode,
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BCarousel,
    BCarouselSlide,
    BButtonGroup,
    BButton,
    BCardText,
    BTabs,
    BLink,
    BTab,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      services: [],
    };
  },
  methods: {
    async getStudentServicesAvailed(student_user_id) {
      try {
        const response = await CommonServices.getStudentServicesAvailed({
          student_user_id,
        });
        if (response.data.status) {
          this.services = response.data.data;
        }
      } catch (err) {
        console.log("Error in getting syudent availed services ", err);
      }
    },
  },
  beforeMount() {
    const { student_user_id } = this.$route.params;
    if (!isNaN(student_user_id)) {
      this.getStudentServicesAvailed(student_user_id);
      this.student_user_id = student_user_id;
    } else {
      console.log("Student User Id is is not a number ", student_user_id);
    }
  },
};
</script>
<style>
.pricing-cards-grid {
  display: grid;
  grid-template-columns: repeat(5, 33%);
  grid-template-rows: 1fr;
  grid-column-gap: 14px;
}
.pricing-card {
  background: linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%);
  border-radius: 15px;
  /* box-shadow: -4px -4px 4px rgba(255, 255, 255, 0.13),
    4px 4px 11px rgba(0, 0, 0, 0.25); */
  /* font-family: "Montserrat", sans-serif; */
}
.pricing-card .pricing-plan-name {
  color: #7aa4ff;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  margin-bottom: 12px;
}
.pricing-card .pricing-cost {
  font-size: 25px;
  text-align: center;
  margin-bottom: 15px;
  font-weight: 600;
  /* font-family: "Alice"; */
}
.pricing-card .pricing-content {
  font-size: 14px;
  margin-bottom: 25px;
  text-align: left;
}
.pricing-card .btn-pay-now {
  background-color: #171717;
  color: #fff;
  border-radius: 20px !important;
  font-weight: 500;
}
.pricing-card .plan-route {
  color: #5367ff;
  text-align: center;
  font-size: 14px;
  margin-top: 18px;
  cursor: pointer;
  font-weight: 500;
}
.pricing-section .pricing-slider .slick-list {
  margin: 0 -30px;
}
.pricing-section .pricing-slider .slick-slide {
  margin: 0 30px;
}
/***********PRICING PLAN TABLE************/

.pricing-plan-table {
  /* font-family: "Montserrat", sans-serif; */
}

.pricing-plan-table td {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
/* .pricing-plan-table .header{
  text-align: center;
} */
.pricing-plan-table thead th {
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
}
.pricing-plan-table .plan-lg-title {
  font-size: 1.5rem;
  /* font-family: "Alice"; */
  font-weight: 600;
}
.pricing-plan-table .plan-name {
  color:  #7aa4ff;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 05px;
}
.pricing-plan-table .plan-price {
  /* font-family: "Alice"; */
  font-size: 22px;
  font-weight: 600;
}
.pricing-plan-table .btn-select-plan {
  background: #e1e1e1;
  border: 1px solid #465cff;
  border-radius: 20px;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
}
.pricing-plan-table .header th {
  position: relative;
}
.pricing-plan-table .header th:after {
  content: "";
  height: 50%;
  width: 1px;
  position: absolute;
  right: 0;
  top: 50%;
  background-color: #00d09c;
}
.pricing-plan-table .header th:last-child:after {
  content: "";
  height: 50%;
  width: 0px;
  position: absolute;
  right: 0;
  top: 50%;
  background-color: #00d09c;
}
.pricing-plan-table .alternate-stripped .plan-detail {
  padding: 1.2rem 0 0.4rem 0;
  font-weight: bold;
  text-transform: uppercase;
}
.pricing-plan-table thead {
  position: sticky;
  top: 0;
  /* position: sticky !important; */
  /* top: 75px; */
  background-color: white;
  box-shadow: 0 0 16px rgb(0 0 0 / 10%);
}

.pricing-plan-table .alternate-stripped td {
  padding: 0.5rem 0;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid #00d09c;
  text-align: center;
}
.pricing-plan-table .alternate-stripped td:last-child {
  border-right: 0px solid transparent;
}
.pricing-plan-table .alternate-stripped td:first-child {
  text-align: left;
}
.pricing-plan-table .alternate-stripped tr:nth-of-type(even) {
  background: rgba(83, 103, 255, 0.04);
}
.pricing-plan-table .table tbody + tbody {
  border-top: 1px solid transparent;
}

/* .pricing-plan-table .table-bordered th, .pricing-plan-table .table-bordered td {
  border-left: 0 solid #fff;
  border-top: 0 solid #fff;
  border-bottom: 0 solid #fff;
  border-right: 1px solid #00D09C ;
}
.pricing-plan-table .table-bordered th:last-child, .pricing-plan-table .table-bordered td:last-child {
  border-right: 0px solid #00D09C ;
} */
/* .pricing-plan-table .table-striped tbody tr:nth-of-type(odd) {
  background: rgba(83, 103, 255, 0.04);
} */

@media (max-width: 768px) {
  .pricing-cards-grid {
    grid-template-columns: repeat(3, 100%);
    overflow: auto;
  }
}
</style>




