<template>
  <div>
    <!-- Upload Doc -->
    <b-modal
      id="add-student-visa-doc-modal"
      ref="student-visa-doc-upload-modal"
      centered
      title="Upload Document"
      ok-title="Submit"
      @show="resetAddStudentDocModal"
      @hidden="resetAddStudentDocModal"
      @ok="studentDocumentUpload"
    >
      <br />
      <b-form-select class="form-group" v-model="document_type">
        <b-form-select-option
          v-for="(item, index) in $store.getters[
            'home/getStudentVisaDocumentTypes'
          ]"
          :key="index"
          :value="item.vdoc_id"
        >
          {{ item.vdoc_name }}
        </b-form-select-option>
      </b-form-select>
      <br />
      <b-form-input
        v-if="document_type === 30"
        v-model="document_extra_label"
        placeholder="Enter Document Name"
      />
      <br />
      <br />
      <b-form-file
        class="form-group"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
        v-model="document_file"
      ></b-form-file>
      <br />
    </b-modal>

    <!-- Remove Doc -->
    <b-modal
      id="remove-student-visa-doc-modal"
      ref="student-visa-doc-remove-modal"
      centered
      title="Remove Document"
      ok-title="Remove"
      @hidden="resetRemoveStudentDocId"
      @ok="removeStudentDoc"
    >
      {{ doc_to_remove ? doc_to_remove.vdoc_name : "" }}
    </b-modal>

    <b-card class="mb-0">
      <div
        v-if="['agent', 'franchise'].includes(user_type)"
        class="custom-search d-flex justify-content-end"
      >
        <div class="demo-inline-spacing">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.add-student-visa-doc-modal
            variant="outline-primary"
          >
            Add document
          </b-button>
        </div>
      </div>
      <br />
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="pagination.perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pagination.perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                class="d-inline-block mr-1"
                placeholder="Search..."
                @input="onSearch"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        id="studentDocumentsTableId"
        ref="refStudentDocumentsTable"
        class="position-relative"
        :items="studentDocsList"
        responsive
        :fields="studentDocumentsColumns"
        :per-page="pagination.perPage"
        :current-page="pagination.currentPage"
        primary-key="svd_id"
      >
        <!-- Index -->
        <template #cell(index)="data">
          {{ studentDocsList.indexOf(data.item) + 1 }}
        </template>

        <!-- Index -->
        <template #cell(vdoc_name)="data">
          {{ data.item.vdoc_name }}
          {{
            data.item.vdoc_id === 30 && data.item.extra_label
              ? ` - ${data.item.extra_label}`
              : ""
          }}
        </template>

        <!-- Open Doc -->
        <template #cell(open)="data">
          <b-button
            @click="openDoc(data.item)"
            variant="light-success"
            class="btn-icon rounded-circle"
            size="30"
            role="button"
          >
            <b-avatar variant="light-success" size="30">
              <feather-icon icon="EyeIcon" size="15" class="text-success" />
            </b-avatar>
          </b-button>
        </template>

        <!-- Comments from operations -->
        <template #cell(doc_approved)="data">
          <b-form-select
            v-if="user_type && ['operations', 'visa'].includes(user_type)"
            v-model="data.item.doc_approved"
            :options="doc_status"
          />
          <p v-else>{{ data.item.doc_approved }}</p>
        </template>

        <!-- Comments from operations -->
        <template #cell(extra_text)="data">
          <b-form-input
            v-if="user_type && ['operations', 'visa'].includes(user_type)"
            v-model="data.item.extra_text"
          />
          <p v-else>{{ data.item.extra_text }}</p>
        </template>

        <!-- Save Status for operations -->
        <template #cell(save)="data">
          <b-button
            role="button"
            variant="outline-success"
            size="sm"
            @click="
              (e) => {
                saveDocStatus(data.item);
              }
            "
          >
            SAVE
          </b-button>
        </template>

        <!-- Remove Doc For Agent -->
        <template #cell(remove_doc)="data">
          <b-button
            v-if="data.item.doc_approved !== 'approved'"
            role="button"
            class="btn-icon rounded-circle"
            variant="light-danger"
            size="sm"
            @click="setDocIdToRemove(data.item)"
          >
            <b-avatar variant="light-danger" size="30">
              <feather-icon icon="TrashIcon" size="15" class="text-danger" />
            </b-avatar>
          </b-button>
          <div v-else></div>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              <!-- Showing {{ dataMeta.from }} to {{ dataMeta.to }} of -->
              {{ studentDocsList.length }} entries
            </span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              aria-controls="studentDocumentsTableId"
              v-model="pagination.currentPage"
              :total-rows="studentDocsList.length"
              :per-page="pagination.perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BBadge,
  BPagination,
  BDropdown,
  BDropdownItem,
  BCard,
  BRow,
  BCol,
  BTable,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BAvatar,
  BFormFile,
  BModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { BASEURL, FILESURL } from "@/config/index";
import CommonServices from "@/apiServices/CommonServices";
import store from "@/store";
import OperationsServices from "@/apiServices/OperationsServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import { TokenService } from "@/apiServices/storageService";
import AgentServices from "@/apiServices/AgentServices";
import axios from "axios";
import helpers from "@/libs/helpers";

export default {
  components: {
    BButton,
    BAvatar,
    BBadge,
    BPagination,
    BDropdown,
    BDropdownItem,
    BCard,
    BRow,
    BCol,
    BTable,
    BFormInput,
    BFormSelect,
    vSelect,
    BFormFile,
    BModal,
    BFormSelectOption,
  },
  directives: {
    Ripple,
  },
  computed: {
    user_type() {
      if (store.getters["user/getUserDetails"].user_type) {
        let type = store.getters["user/getUserDetails"].user_type;
        if (["operations", "visa"].includes(type)) {
          let temp_arr = this.studentDocumentsColumns;
          if (!temp_arr.includes({ key: "save", label: "Save" })) {
            temp_arr.push({ key: "save", label: "Save" });
            this.studentDocumentsColumns = temp_arr;
          }
        } else if (["agent", "franchise"].includes(type)) {
          let temp_arr = this.studentDocumentsColumns;
          if (!temp_arr.includes({ key: "remove_doc", label: "Remove" })) {
            temp_arr.push({ key: "remove_doc", label: "Remove" });
            this.studentDocumentsColumns = temp_arr;
          }
        }
        return type;
      } else {
        return null;
      }
    },
  },
  data() {
    return {
      doc_to_remove: null,
      document_type: null,
      document_file: null,
      document_extra_label: null,
      student_user_id: null,
      studentDocsList: [],
      fullStudentDocsList: [],
      studentDocumentsColumns: [
        { key: "index", label: "#" },
        { key: "vdoc_name", label: "Type of document" },
        { key: "open", label: "Preview" },
        { key: "doc_approved", label: "Status" },
        { key: "extra_text", label: "Comments" },
        {
          key: "created_at",
          label: "Upload Date",
          formatter: helpers.getDateTimeFormatted,
        },
        // { key: "save", label: "Save" },
      ],
      doc_status: [
        { value: "pending", text: "Pending" },
        { value: "approved", text: "Approved" },
        { value: "rejected", text: "Rejected" },
      ],
      pagination: {
        currentPage: 1,
        perPage: 20,
        perPageOptions: [5, 10, 20, 30],
      },
    };
  },
  methods: {
    resetAddStudentDocModal() {
      this.document_file = null;
      this.document_type = null;
    },
    resetRemoveStudentDocId() {
      this.doc_to_remove = null;
    },
    setDocIdToRemove(doc) {
      this.$refs["student-visa-doc-remove-modal"].show();
      this.doc_to_remove = doc;
    },
    removeStudentDoc() {
      const svd_id = this.doc_to_remove.svd_id;
      AgentServices.removeStudentVisaDocument({
        student_user_id: this.student_user_id,
        svd_id,
      })
        .then((response) => {
          if (response.data.status) {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Document Removed",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.getStudentVisaDocuments();
          } else {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: response.data.message || "Document Remove Failed",
                icon: "EditIcon",
                variant: "failure",
              },
            });
          }
        })
        .catch((err) => {
          console.log("Error removing document ", err);
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Server Error",
              icon: "EditIcon",
              variant: "failure",
            },
          });
          this.$nextTick(() => {
            this.$refs["student-visa-doc-remove-modal"].toggle("#toggle-btn");
          });
        });
      this.getStudentVisaDocuments();
    },
    async studentDocumentUpload(e) {
      e.preventDefault();

      if (!this.document_file) {
        helpers.showErrorToast(this, "Please Upload Document");
        return;
      }

      if (!this.document_type) {
        helpers.showErrorToast(this, "Select Document Type");
        return;
      }

      const format = /[^\x00-\x7F]/;
      if (format.test(this.document_file.name)) {
        helpers.showErrorToast(
          this,
          "Filename cannot contain special characters"
        );
        return;
      }

      if (this.document_file.size > 10 * 1024 * 1024) {
        helpers.showErrorToast(this, "Filesize cannot be greater than 5MB");
        return;
      }

      if (this.document_type === 30 && !this.document_extra_label) {
        helpers.showErrorToast(this, "Enter Document Name");
        return;
      }

      try {
        const AUTH_TOKEN = TokenService.getToken();

        const formData = new FormData();
        formData.append("doc", this.document_file);

        const document_extra_label = this.document_extra_label;
        this.document_extra_label = null;

        const response = await axios.post(
          `${BASEURL}/agent/upload-student-visa-doc/${this.student_user_id}/${this.document_type}`,
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              authorization: AUTH_TOKEN,
            },
            params: {
              document_extra_label,
            },
          }
        );
        if (response.data.status) {
          this.getStudentVisaDocuments();
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Document Uploaded Successfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$nextTick(() => {
            this.$refs["student-visa-doc-upload-modal"].toggle("#toggle-btn");
          });
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Document Uploaded Failed",
              icon: "X",
              variant: "failure",
            },
          });
        }
      } catch (err) {
        console.log("Error uploading file ", err);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Server Error",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }
    },
    onSearch(text) {
      this.studentDocsList = this.fullStudentDocsList.filter((doc, index) => {
        return doc.vdoc_name.toLowerCase().includes(text.toLowerCase());
      });
    },
    openDoc(doc) {
      const url = FILESURL + doc.vdoc_url;
      window.open(url);
    },
    async saveDocStatus(doc) {
      try {
        console.log({ doc });

        const response = await OperationsServices.setVisaDocumentStatus({
          student_user_id: this.student_user_id,
          svd_id: doc.svd_id,
          doc_approved: doc.doc_approved,
          extra_text: doc.extra_text,
        });
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Status Saved",
              icon: "EditIcon",
              variant: "failure",
            },
          });
          // this.getStudentVisaDocuments(this.student_user_id);
        }
      } catch (error) {
        console.log("Error in saving doc status");
      }
    },
    async getStudentVisaDocuments() {
      try {
        const response = await CommonServices.getStudentVisaDocuments({
          student_user_id: this.student_user_id,
        });
        if (response.data.status) {
          this.studentDocsList = response.data.data;
          this.fullStudentDocsList = response.data.data;
        }
      } catch (error) {
        console.log("Error in getting Student Documents ", error);
      }
    },
  },
  beforeMount() {
    const { student_user_id } = this.$route.params;
    if (!isNaN(student_user_id)) {
      this.student_user_id = student_user_id;
      this.getStudentVisaDocuments(student_user_id);
    } else {
      console.log("Student User Id is is not a number ", student_user_id);
    }
    this.$store.dispatch("home/onGetStudentVisaDocumentTypes");
  },
};
</script>


<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

