import axiosInstance from "./Api";

export default {
  async getHomeData() {
    return await axiosInstance().get(`/counselor/home-data`);
  },

  async getAssignedAgents(payload) {
    let response = await axiosInstance().get(`/counselor/assigned-agents`, {
      params: payload,
    });

    return response;
  },

  async saveProfileExtraInfo(payload) {
    let response = await axiosInstance().post(
      `/counselor/save-application-limit/${payload.student_user_id}`,
      payload
    );

    return response;
  },

  async suggestCourseStudent(payload) {
    const { course_id, student_user_ids } = payload;

    let response = await axiosInstance().post(
      `/counselor/student/suggest-course/${course_id}`,
      { student_user_ids }
    );

    return response;
  },

  async removeSuggestion(payload) {
    const { student_user_id, course_id } = payload;

    let response = await axiosInstance().post(
      `/counselor/student/${student_user_id}/remove-suggestion/${course_id}`
    );

    return response;
  },

  async setStudentConverted(payload) {
    const { student_user_id, convert_status } = payload;

    let response = await axiosInstance().post(
      `/counselor/student/${student_user_id}/converted/${convert_status}`
    );

    return response;
  },

  async setStudentStatus(payload) {
    const { status, student_user_id } = payload;

    let response = await axiosInstance().post(
      `/counselor/student/${student_user_id}/active-status/${status}`
    );

    return response;
  },

  async saveAgentCommissionForApplication(payload) {
    const { student_user_id } = payload;

    let response = await axiosInstance().post(
      `/counselor/student/${student_user_id}/application-commission`,
      payload
    );

    return response;
  },

  async getStudentLeads(payload) {
    return await axiosInstance().get(`/counselor/student/leads`, {
      params: payload,
    });
  },

  async getFranchiseQuizResponse(payload) {
    const { agent_user_id } = payload;
    return await axiosInstance().get(`/counselor/franchise-quiz-responses/${agent_user_id}`, payload);
  }
};
